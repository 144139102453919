import { mapState, mapGetters, mapActions } from "vuex";

export const authComputed = {
  ...mapState("auth", {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters("auth", ["loggedIn"]),
};

export const layoutComputed = {
  ...mapState("layout", {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};

export const authMethods = mapActions("auth", [
  "logIn",
  "logOut",
  "register",
  "resetPassword",
]);

export const layoutMethods = mapActions("layout", [
  "changeLayoutType",
  "changeLayoutWidth",
  "changeLeftSidebarType",
  "changeTopbar",
  "changeLoaderValue",
]);

export const authFackMethods = mapActions("authfack", [
  "login",
  "registeruser",
  "logout",
]);

export const notificationMethods = mapActions("notification", [
  "success",
  "error",
  "clear",
]);

export const userMethods = mapActions("user", [
  "fetch",
  "store",
  "changeFilter",
  "delete",
  "detail",
  "update",
]);

export const categoryMethods = mapActions("category", [
  "fetchCategories",
  "create",
  "changeFilter",
  "delete",
  "detail",
  "update",
]);

export const clientMethods = mapActions("client", [
  "fetchClients",
  "create",
  "changeFilter",
  "delete",
  "detail",
  "update",
]);

export const portfolioMethods = mapActions("portfolio", [
  "fetchPortfolio",
  "create",
  "changeFilter",
  "delete",
  "detail",
  "update",
]);

export const tagMethods = mapActions("tag", [
  "fetch",
  "create",
  "changeFilter",
  "delete",
  "detail",
  "update",
]);

export const bankQnaMethods = mapActions("bankQna", [
  "fetch",
  "create",
  "changeFilter",
  "delete",
  "detail",
  "update",
]);

export const courseMethods = mapActions("course", [
  "fetch",
  "create",
  "changeFilter",
  "delete",
  "detail",
  "update",
]);
